import React from "react";
import {Link} from "react-router-dom";

export default function SubHeader({title}: { title: string }) {


    return (
        <div className="sub-header">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb" className="text-center breadcrumb-nav">
                            <h2 className="Page-title">{title}</h2>
                            <ol className="breadcrumb">
                                <li>
                                    <i className="fa fa-home"></i>
                                    <Link to={'/'}>Accueil</Link>
                                    <i className="fa fa-angle-right"></i>
                                </li>
                                <li><a href="#">{title}</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}
