import React, {useContext} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Video} from "../models/Video";
import useToast from "../hook/useToast";
import {AppContext} from "../context/AppContext";
import {toast} from "react-toastify";
import useUser from "../hook/useUser";

export default function VideoSliderItem(video: Video) {
    const {session} = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const {triggerConnectionRequieredToast} = useToast()
    const {postVideoToFavorite} = useUser()


    const addToFavorite = (event: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
        if (session) {
            const toastId = toast.loading(`Ajout de ${video.title} aux favoris...`, {theme: 'dark'})
            postVideoToFavorite(session!.user.email!, video.id)
                .then(() => toast.update(toastId, {
                    render: `${video.title} ajouté aux favoris !`, type: "success", isLoading: false,
                    onClick: () => navigate('/favorites'),
                    autoClose: 3000
                }))
                .catch(() => toast.update(toastId, {
                    render: `Error adding ${video.title} to favorite, retry late.`,
                    type: "error"
                }))
        } else {
            triggerConnectionRequieredToast()
        }
        event.stopPropagation()
    }

    const navigateToVideoPlayer = (videoId: string) => {
        if (location.pathname.includes('videos-player')) {
            window.location.href = `${location.pathname}?id=${videoId}`
        } else {
            navigate(`/videos-player?id=${videoId}`)
        }
        window.scroll(0,0)
    }

    return (
        <div className="item">
            <a onClick={() => {
                if (session) {
                    navigateToVideoPlayer(video.id)
                } else {
                    triggerConnectionRequieredToast()
                }
            }}
            >
                <div className="video-block">
                    <div className="video-thumb position-relative thumb-overlay" style={{borderRadius: '8px'}}>
                        <img alt="" className="img-fluid" src={`${video.thumbPath}`}/>
                        {location.pathname !== '/favorites' && <div className="box-content">
                            <ul className="icon"
                                onClick={addToFavorite}
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    borderBottomLeftRadius: 10,
                                    display: "flex",
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <li style={{
                                    maxHeight: "25px",
                                    overflow: "hidden",
                                    display: 'flex',
                                    flexDirection: "column-reverse",
                                }}>
                                    <div>
                                        <i className="fas fa-plus"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>}
                    </div>
                    <div className="video-content">
                        <h2 className="video-title">{video.title}</h2>
                        <div className="video-info d-flex align-items-center"><span
                            className="video-type">{video.theme}</span>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )

};
