import { AxiosError } from 'axios';

export default function useError() {
    function onHttpError(error: AxiosError, path: string, data?: {}) {
        console.error(
            `Path: ${path}\nError: ${JSON.stringify(error)}${
                data && `\nData: ${JSON.stringify(data)}`
            }`,
        );
    }

    return { onHttpError };
}

