import useFetch from "./useFetch";
import {User} from "../models/User";

export default function useUser() {
    const host = process.env.REACT_APP_API_URL;

    const {getRequest, postRequest} = useFetch();

    function getUserByEmail(email: string) {
        return getRequest<User | null>(`${host}/users/${email}`);
    }

    function postUser(email: string) {
        return postRequest<User, void>(`${host}/users`, {email});
    }

    function postVideoToFavorite(email: string, videoId: string) {
        return postRequest<void, void>(`${host}/users/${email}/videos/${videoId}`);
    }





    return {getUserByEmail, postUser, postVideoToFavorite}
}
