export interface Video {
    id: string;
    theme: Themes;
    title: string;
    description: string;
    duration: string;
    url: string;
    thumbPath: string;
    age: number;
}

export enum Themes {
    ANIMES = "Animés",
    BANDES_ANNONCES = "Bandes-annonces",
    JEUX_VIDEOS = "Jeux vidéos",
    INTERVIEWS = "Interviews",
    MANGAS = "Mangas",
    MUSIQUES = "Musiques",
    REPORTAGES = "Reportages",
    CINEMA = 'Cinema'
}


