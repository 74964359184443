import React, {useContext, useEffect, useRef, useState} from 'react';
import Shell from "../components/Shell";
import VideosSlider from "../components/VideosSlider";
import {Video} from "../models/Video";
import {Link} from "react-router-dom";
import {MutatingDots} from "react-loader-spinner";
import {AppContext} from "../context/AppContext";
import useUser from "../hook/useUser";

export default function Favorites() {

    const {session} = useContext(AppContext)
    const {getUserByEmail} = useUser()
    const videos = useRef<Video[]>([])
    const [isLoading,setIsLoading] = useState(true)

    useEffect(() => {
        getUserByEmail(session?.user.email!).then(res => {
            videos.current = res!.favoriteVideos!
            setIsLoading(false)
        })
    }, []);




    const renderVideos = () => {
        return videos.current && videos.current.length > 0 ?
            <VideosSlider title={"VIDEOS FAVORITES"} videos={videos.current}/> :
            <h2 style={{textAlign: "center", fontSize: "16px"}}> Aucune vidéo n'a été ajouté à vos favoris &#128533; </h2>
    }

    return (
        <Shell>
            <div className={"main-content"}>
                {isLoading ? <div className={"row align-items-center justify-content-center"}>
                    <MutatingDots
                        visible={true}
                        height="100"
                        width="100"
                        color="#e74e21"
                        secondaryColor="#e74e21"
                        radius="12.5"
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /></div> : renderVideos()}
                <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop:"50px"}}>
                    <Link to={'/videos'} className={"btn hvr-sweep-to-right ml-3"}>Plus de vidéos</Link>
                </div>
            </div>
        </Shell>
    )
}
