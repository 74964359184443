import React from "react";
import Shell from "../components/Shell";
import SubHeader from "../components/SubHeader";

export default function Policy() {


    return (
        <Shell>
            <>
                <SubHeader title={'Private policy'}/>
                <div className="main-content">
                    <section className="terms">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="terms-use">
                                        <div className="terms-item mb-4">
                                            <h2>1. OUR APPROACH TO PRIVACY</h2>
                                            <p>We want to make sure that when we collect information from you we do so
                                                in a way that does not infringe your right to privacy. This Privacy
                                                Policy is designed to give you useful information about your rights and
                                                the way we collect and use personal information.

                                                Please note that by visiting and using this site you are agreeing to the
                                                use of your personal information as described in this Privacy Policy.
                                                This policy may change from time to time. If we do make changes to it,
                                                we will post them on this page so that you are always aware of the
                                                information that we collect, how we use it, and under what circumstances
                                                we disclose it. These changes will have immediate effect.
                                            </p>
                                        </div>
                                        <div className="terms-item mb-4">
                                            <h2>THE INFORMATION WE COLLECT AND HOW WE USE IT</h2>
                                            <p>We collect personally identifiable information about you when you:</p>
                                            <ul className="mt-3 mb-3">
                                                <li className={"mb-1"}>if you subscribe to receive our newsletters via the 'Alert News'
                                                    page we collect your e-mail address. This newsletter includes
                                                    details of our offers, podcasts for download and other goodies. We
                                                    only use this information to send you our ne
                                                </li>
                                                <li>if you enter any of our contests or prize draws, we collect your
                                                    name, postal address, e-mail address, telephone numbers and date of
                                                    birth. We only use this information to administer the contest or
                                                    prize draw that you have entered.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="terms-item mb-4">
                                            <h2>INFORMATION SHARING AND DISCLOSURE </h2>
                                            <p>We do not sell or rent your personal information. W e may , however ,
                                                give information about you to our employees and/or agents for the sole
                                                purpose of administering any services provided to you through or in
                                                connection with our site (e.g. prize draws, newsletters). If so, we
                                                always require those third parties to adhere to the same security
                                                procedures that we follow ourselves. Lastly, if ownership or operational
                                                control of all or part of our site were transferred, whether as part of
                                                the sale of GONG's business or otherwise, we may disclose your personal
                                                information to the transferee. If that happens, we would require them to
                                                use your information in a way that is consistent with this Privacy
                                                Policy. </p>
                                        </div>
                                        <div className="terms-item mb-4">
                                            <h2>AGGREGATED INFORMATION </h2>
                                            <p>We may share non-personal aggregated statistics data about visitors to
                                                this site and sales and traffic patterns, with other parties. Just to be
                                                clear, this information does not identify users in any personal
                                                capacity; it just gives generalised information about the users of our
                                                site. COOKIES While on the site, certain information is automatically
                                                logged about how you are using the site. This information may include
                                                the URL of the website which linked you to this site, your IP address
                                                and the pages you visit while on our site. The IP address indicates the
                                                location of your computer on the internet. IP addresses are not used to
                                                track your session and are not linked to anything personally
                                                identifiable. A feature known as a cookie is also used. Cookies are
                                                small pieces of information stored by your internet browser onto your
                                                computer's hard drive so that websites can remember who you are. In
                                                effect, cookies tell us when you have visited our site and where you
                                                have been, but they do not identify you personally - just the presence
                                                of your browser. We use cookies to see how often people visit our site
                                                and what they look at. This helps us to know what parts of the site are
                                                popular - what people are interested in can make sure we have more
                                                interesting material on the site in future. Most browsers are set up to
                                                accept cookies, but you can configure your browser to refuse cookies or
                                                to notify you when you received one. If you want to stop cookies being
                                                stored on your computer in future, please refer to your browser
                                                manufacturer's instructions by clicking "Help" in your browser menu.
                                                Further information on deleting or controlling cookies is available at
                                                www.AboutCookies.org. If you choose not to accept these cookies, your
                                                experience at this site and third party websites may diminished and some
                                                features may not work as intended. </p>
                                        </div>
                                        <div className="terms-item mb-4">
                                            <h2>LINKS</h2>
                                            <p>This site contains links to third party websites that are not subject to
                                                this privacy policy. Please read Links from this Site in our of Use for
                                                further information on these links. We strongly recommend that you read
                                                the privacy policy of any such websites visit before providing them with
                                                your personal information.</p>
                                        </div>
                                        <div className="terms-item mb-4">
                                            <h2>SECURITY POLICY </h2>
                                            <p>We use reasonable precautions to keep the information disclosed to us
                                                secure. However, we cannot guarantee the security data you disclose
                                                on-line. You accept the inherent security risks of providing information
                                                and dealing on-line over the Internet not hold us responsible for any
                                                breach of security unless this is due to our negligence or willful
                                                default.</p>
                                        </div>
                                        <div className="terms-item mb-4">
                                            <h2>ACCESS TO YOUR INFORMATION</h2>
                                            <p>You have the right to see your personal information (as defined under the
                                                Data Protection Act 1988) that we keep about you, receipt of a written
                                                request and payment of a fee. If you are concerned that any of the
                                                information we hold on you is incorrect please let us know and we will
                                                amend. You can write us at GONG Media Ltd, Curzon House, 24 High Street,
                                                Banstead, Surrey SM7 2LJ, United Kingdom or via e-mail at
                                                contact@gongnetworks.com</p>
                                        </div>
                                        <div className="terms-item">
                                            <h2>HOW TO CONTACT US</h2>
                                            <p>If you have any questions about our privacy practices, please contact us
                                                by writing to Privacy Officer, GONG Media, Curzon House, 24 High Street,
                                                Banstead, Surrey SM7 2LJ, United Kingdom or by sending an e-mail to
                                                contact@gongnetworks.com </p>

                                            <p>© 2006-2016 GONG Media Ltd and its licensors. All rights reserved</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        </Shell>
    )
}
