import React, {useContext, useEffect} from 'react';
import Shell from "../components/Shell";
import {Session} from "@supabase/supabase-js";
import {Auth} from "@supabase/auth-ui-react";
import {I18nVariables, ThemeSupa} from "@supabase/auth-ui-shared";
import {AppContext} from "../context/AppContext";
import {useNavigate} from "react-router-dom";
import useUser from "../hook/useUser";
import {toast} from "react-toastify";


export default function Login() {

    const {setSession, supabase} = useContext(AppContext);
    const {postUser, getUserByEmail} = useUser();
    const navigate = useNavigate();

    async function login(session: Session | null) {
        setSession(session)
        const user = await getUserByEmail(session?.user?.email!);
        if (!user) {
            const loadingToastId = toast.loading("Inscription en cours...", {theme: "dark"})
            postUser(session?.user?.email!)
                .then(() => toast.update(loadingToastId, {
                        type: "success",
                        render: "Inscription réussi, bienvenue ! 🥳",
                        isLoading: false,
                        closeOnClick:true,
                        autoClose: 3000
                    }),
                    () => toast.update(loadingToastId, {
                        type: 'error',
                        render: "Echec de l'inscription, ré-essaie ultérieurement 😕",
                        isLoading: false,
                        closeOnClick:true,
                        autoClose: 3000
                    }))
        } else {
            toast("Bon retour parmi nous ! 🙌", {
                type: "success",
                theme: "dark",
                autoClose: 3000,
                closeOnClick:true,
            })
        }
        navigate('/')
    }
    const supaTexts: I18nVariables = {
        sign_up: {
            email_label: "Email",
            password_label: "Mot de passe",
            email_input_placeholder: "Votre email",
            password_input_placeholder: "Votre mot de passe",
            button_label: "S'inscrire",
            loading_button_label: "Inscription en cours...",
            social_provider_text: "Inscription avec Google",
            link_text: "Tu n'as pas de compte ? Inscris toi ici !",
            confirmation_text: "Vérifie ton email pour le lien de confirmation",
        },
        sign_in: {
            email_label: "Email",
            password_label: "Mot de passe",
            email_input_placeholder: "Votre email",
            password_input_placeholder: "Votre mot de passe",
            button_label: "Se connecter",
            loading_button_label: "Connexion en cours...",
            social_provider_text: "Connexion avec Google",
            link_text: "Tu as déjà un compte ? Connecte toi !",
        },
        magic_link: {
            email_input_label: "Email",
            email_input_placeholder: "Votre email",
            button_label: "Envoyer un lien magique",
            loading_button_label: "Envoi du lien magique...",
            link_text: "Envoyer un email avec un lien magique",
            confirmation_text: "Vérifie ton email pour le lien magique",
            empty_email_address: "L'adresse email ne peut pas être vide",
        },
        forgotten_password: {
            email_label: "Email",
            password_label: "Mot de passe",
            email_input_placeholder: "Votre email",
            button_label: "Envoyer le lien de réinitialisation",
            loading_button_label: "Envoi du lien de réinitialisation...",
            link_text: "Mot de passe oublié ?",
            confirmation_text: "Vérifie ton email pour le lien de réinitialisation",
        },
        update_password: {
            password_label: "Nouveau mot de passe",
            password_input_placeholder: "Votre nouveau mot de passe",
            button_label: "Mettre à jour le mot de passe",
            loading_button_label: "Mise à jour du mot de passe...",
            confirmation_text: "Votre mot de passe a été mis à jour",
        },
        verify_otp: {
            email_input_label: "Email",
            email_input_placeholder: "Votre email",
            phone_input_label: "Numéro de téléphone",
            phone_input_placeholder: "Votre numéro de téléphone",
            token_input_label: "OTP",
            token_input_placeholder: "Votre code OTP",
            button_label: "Vérifier OTP",
            loading_button_label: "Vérification OTP en cours...",
        },
    };



    useEffect(() => {
        const {
            data: {subscription},
        } = supabase!.auth.onAuthStateChange((_event, session) => {
            session && login(session);
        })

        return () => subscription.unsubscribe()
    }, [])

    return (
        <Shell>
            <div className={"main-content"}>
                <section className="login">
                    <div className="container">
                        <div className="row text-center intro">
                            <div className="col-12">
                                <h2>Connecte toi</h2>
                                <p className="text-max-800">
                                    Connecte toi gratuitement pour accéder à l'ensemble des fonctionnalités de
                                    l'application !
                                </p>
                            </div>
                            {/* Col End */}
                        </div>
                        <div className={"row justify-content-center align-items-center"}>
                            <div className="col-8">
                                <Auth supabaseClient={supabase!} dark={true} localization={{variables: supaTexts}}
                                      view={'sign_up'} appearance={{theme: ThemeSupa}} theme="dark"
                                      providers={[]}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Container End */}
                </section>
            </div>
        </Shell>
    );

}
