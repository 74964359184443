import React, {JSX, useContext, useEffect} from "react";

import PreLoader from "./PreLoader";
import SearchModal from "./SearchModal";
import Navigations from "./Navigations";
import Footer from "./Footer";
import {ToastContainer} from "react-toastify";
import {AppContext} from "../context/AppContext";

export default function Shell({children}: { children: JSX.Element }) {

    const {windowWidth} = useContext(AppContext)

    useEffect(() => {
        // @ts-ignore
        window.runMainJs();
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <PreLoader/>
            <SearchModal/>
            {windowWidth > 500 && <ToastContainer />}
            <div className="main" id="main" style={{overflow:'hidden'}}>
                <Navigations/>
                <div style={{filter:'grayscale(0%)'}}>
                    {children}
                </div>
                {windowWidth <= 500 && <ToastContainer/>}
                <Footer/>
            </div>
        </>
    )
}
