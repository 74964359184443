import axios, {AxiosRequestConfig} from "axios";
import useError from "./useError";

export default function useFetch() {
    const { onHttpError } = useError();

    function getRequest<T = void, P = void>(path: string, params?: P) {
        return axios
            .get<T>(path, { params: params })
            .then(axiosResponse => {
                return axiosResponse.data;
            })
            // @ts-ignore
            .catch(error => onHttpError(error, path, params));
    }

    function postRequest<D = void, T = void>(
        path: string,
        data?: D,
        options?: AxiosRequestConfig<D>,
    ) {
        return axios
            .post<T>(path, data, options)
            .then(axiosResponse => {
                return axiosResponse.data;
            })
            // @ts-ignore
            .catch(error => onHttpError(error, path, data));
    }

    function patchRequest<D = void, T = void>(
        path: string,
        data?: D,
        options?: AxiosRequestConfig<D>,
    ) {
        return axios
            .patch<T>(path, data, options)
            .then(axiosResponse => {
                return axiosResponse.data;
            })
            .catch(error => onHttpError(error, path));
    }

    function putRequest<D = void, T = void>(path: string, data?: D) {
        return axios
            .put<T>(path, data)
            .then(axiosResponse => axiosResponse.data)
            .catch(error => onHttpError(error, path));
    }

    return {
        getRequest,
        postRequest,
        patchRequest,
        putRequest,
    };
}
