import React, {useContext, useEffect, useState} from 'react';
import avatar from "../images/avatar/user.jpg"
import logo from "../images/logo/header-logo.png"
import {Link, useNavigate} from "react-router-dom";
import {AppContext} from "../context/AppContext";
import {toast} from "react-toastify";
import useToast from "../hook/useToast";


export default function Navigations() {
    const {windowWidth, session, setSession, supabase} = useContext(AppContext);
    const {triggerConnectionRequieredToast} = useToast()
    const navigate = useNavigate();



    const isMobileNav = windowWidth <= 991

    function logout() {
        setSession(null);
        supabase?.auth.signOut();
        navigate('/');
        toast("Déconnecté 👋", {
            autoClose: 3000,
            theme: 'dark',
            closeOnClick: true,
            type: 'info',
        })
    }



    return (
        <>
            <div id="test-jquery">
                {/* Start Mobile Navigations */}
                <header className="mobile-header">
                    <div className="mobile-header-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mobile-logo text-center">
                                        <Link to={'/'}><img alt="" className="img-fluid header-logo"
                                                            src={logo}/></Link>
                                    </div>
                                </div>
                                {/* Logo Col End */}
                            </div>
                            {/* Row End */}
                        </div>
                        {/* Container End */}
                    </div>
                    {/* Navigations Content End */}
                </header>
                {/* Mobile Navigations End */}
                {/* Start Navigations */}
                <header className="main-nav clearfix is-sticky">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            <div className="col-lg-9 pl-0">
                                {/* Start Navigation */}
                                <nav className="navbar navbar-expand-lg">
                                    <Link className="navbar-brand" to="/"><img alt=""
                                                                               className="img-fluid header-logo"
                                                                               src={logo}/></Link>
                                    {/* Logo End */}
                                    <div className="site-nav-inner float-left">
                                        <button aria-controls="navbarSupportedContent" aria-expanded="true"
                                                aria-label="Toggle navigation" className="navbar-toggler"
                                                data-target="#navbarSupportedContent" data-toggle="collapse"
                                                type="button"><span className="fa fa-bars"/></button>
                                        {/* Navbar Toggler End */}
                                        <div className="navbar-collapse navbar-responsive-collapse collapse"
                                             id="navbarSupportedContent">
                                            <ul className="nav navbar-nav">
                                                <li className="nav-item">
                                                    <Link
                                                        to={'/'} className="nav-link">Accueil</Link>
                                                </li>
                                                <li className="nav-item">
                                                    {session ?
                                                        <Link
                                                            to={'/live'} className="nav-link">Direct</Link>
                                                        :
                                                        <Link
                                                            to={''} onClick={() => {
                                                            triggerConnectionRequieredToast()
                                                        }} className="nav-link">Direct</Link>
                                                    }

                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to={'/videos'} className="nav-link">Videos</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        href="https://www.dramapassion.com/"
                                                        target={"_blank"}
                                                        className="nav-link">DramaPassion</a>
                                                </li>
                                                {!isMobileNav ? <li className="nav-item dropdown">
                                                    <a className="nav-link menu-dropdown" data-toggle="dropdown"
                                                       href="#">PLUS <i className="fa fa-angle-down"/></a>
                                                    <ul className="dropdown-menu fade-up" role="menu">
                                                        <li>
                                                            <Link data-target="#navbarSupportedContent"
                                                                  data-toggle="collapse" to={'/about'}
                                                                  className="dropdown-item">A PROPOS</Link>
                                                        </li>
                                                        {/* Sub Nav Item 1 End */}
                                                        <li>
                                                            <Link data-target="#navbarSupportedContent"
                                                                  data-toggle="collapse" to={'/contact'}
                                                                  className="dropdown-item">CONTACTE NOUS</Link>
                                                        </li>
                                                    </ul>
                                                    {/* Dropdown End */}
                                                </li> : <>
                                                    <li className="nav-item">
                                                        <Link
                                                            to={'/about'} className="nav-link">A PROPOS</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link
                                                            to={'/contact'} className="nav-link">CONTACTE NOUS</Link>
                                                    </li>
                                                </>}

                                                {/* Nav Item 4 End */}
                                            </ul>
                                            {/* Nav UL End */}
                                        </div>
                                        {/* Navbar Collapse End */}
                                    </div>
                                    {/* Site Nav Inner End */}
                                </nav>
                                {/* Navigation End */}
                            </div>
                            {/* Col End */}
                            <div
                                className="col-lg-3 text-right right-menu-wrap">
                                <ul className="nav d-flex align-items-center list-inline m-0 float-right">
                                    {/* Nav Item 2 End */}
                                    <div>{session?.user.email ?? ''}</div>
                                    <li className="nav-item">
                                        <div className="nav-account ml-2">
                                            <div className="dropdown">
                                                <div aria-expanded="false" aria-haspopup="true" data-toggle="dropdown"
                                                     id="dropdown-account" role="button">
                                                    <img alt=""
                                                         className="img-fluid user-icon rounded-circle"
                                                         src={avatar}
                                                    />
                                                </div>
                                                <ul className="dropdown-menu dropdown-menu-right fade-up avatar-menu">
                                                    <li>
                                                        <Link onClick={() => {
                                                            !session && triggerConnectionRequieredToast()
                                                        }} className="dropdown-item" to={session ?'/favorites':''}>Videos
                                                            favorites</Link>
                                                    </li>
                                                    {session ? <li>
                                                        <Link className="dropdown-item" to={''} onClick={() => {
                                                            logout();
                                                        }}>Se déconnecter</Link>
                                                    </li> : <li>
                                                        <Link className="dropdown-item" to={'/connexion'}>Se
                                                            connecter</Link>
                                                    </li>}
                                                </ul>
                                                {/* Account List End */}
                                            </div>
                                            {/* Account Drop Down End */}
                                        </div>
                                        {/* Account Menu End */}
                                    </li>
                                    {/* Nav Item 3 End */}
                                </ul>
                                {/* Nav UL End */}
                            </div>
                            {/* Col End */}
                        </div>
                        {/* Row End */}
                    </div>
                    {/* Container End */}
                </header>
                {/* Navigations End */}
            </div>

        </>
    );
};
