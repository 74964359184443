import React from 'react';

export default function SearchModal() {
    return (
        <div className="zoom-anim-dialog mfp-hide modal-searchPanel search-form" id="search-popup">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="search-panel">
                        <form className="search-group">
                            <div className="input-group">
                                <input className="form-control" name="s" placeholder="Nom de la vidéo que vous chercher..."
                                       />
                                <button className="input-group-btn search-button"><i className="fas fa-search"/>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

}
