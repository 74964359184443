import React from 'react';
import VideoSliderItem from "./VideoSliderItem";
import {Video} from "../models/Video";
import OwlCarousel from "react-owl-carousel";
import {Options} from "react-owl-carousel/src/options";

export default function VideosSlider({title, videos}: { title: string, videos: Video[] }) {
    const disableLoop = ()=>{
        if(window.innerWidth > 1500 ){
            return videos.length > 5
        }
        if(window.innerWidth > 1000 ){
            return videos.length > 4
        }
        if(window.innerWidth > 700 ){
            return videos.length > 3
        }
        if(window.innerWidth <= 700 ){
            return videos.length > 2
        }
        return false;
    }

    const enableDots = ()=>{
        if(window.innerWidth > 1500 ){
            return videos.length > 5 && videos.length < 50
        }
        if(window.innerWidth > 1000 ){
            return videos.length > 4 && videos.length < 40
        }
        if(window.innerWidth <= 700 ){
            return videos.length > 3 && videos.length < 30
        }
        return false;
    }

    const carouselProps: Options = {
        loop: disableLoop(),
        margin: 30,
        autoplay: false,
        items: 7,
        dots: enableDots(),
        responsive: {
            0: {
                items: 2
            },
            700: {
                items: 3
            },
            1000: {
                items: 4
            },
            1500: {
                items: 5
            }
        },
    }



    return (
        <section className="pupular video-slider">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="block-title">{title}</h2>
                        <OwlCarousel {...carouselProps} dotsClass={"container__owl-dot"} >
                            {videos.length > 0 && videos.map((video, index) => {
                                return <VideoSliderItem key={video.id} id={video.id} title={video.title}
                                                        description={video.description} url={video.url}
                                                        theme={video.theme} thumbPath={`../images/videos/${video.thumbPath}`}
                                                        duration={video.duration} age={video.age}/>
                            })}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>

    )

};


