import React, {JSX, useContext, useEffect, useState} from 'react';
import {Link, To, useLocation} from "react-router-dom";
import useToast from "../hook/useToast";
import {AppContext} from "../context/AppContext";

export type CarouselItemProps = {
    id: number;
    backgroundImage: string;
    title: string;
    description: string;
    duration: string;
    minimumAge: string;
    buttons: CarouselButton[]
}
export type CarouselButton = {
    label: string,
    to: To,
    icon: JSX.Element;
}

export default function HomePageCarouselItem(itemProps: CarouselItemProps) {

    const [animationDelay, setAnimationDelay] = useState(0)
    const {triggerConnectionRequieredToast} = useToast()

    const {session} = useContext(AppContext);

    useEffect(() => {
        itemProps.id === 1 && slowAnimationForFirstRender()
    }, [])


    const slowAnimationForFirstRender = () => {
        setAnimationDelay(0.5)
    }

    const handleConnexionRequired = (to: To) => {
        if (!session && to === '/live') {
            return ''
        }
        return to;
    }

    const isOutsideRedirection = (to:To) =>
    {
        if (typeof to === 'string') {
            return to.includes('http');
        } else if (to && typeof to.pathname === 'string') {
            return to.pathname.includes('http');
        }
        return false;
    }

    const buttonsList = itemProps.buttons.map((button, index) =>
        <Link
            onClick={() => {
                !session && button.to === '/live' && triggerConnectionRequieredToast()
            }}
            className="btn hvr-sweep-to-right mr-3"
            to={handleConnexionRequired(button.to)}
            target={isOutsideRedirection(button.to as string) ? '_blank' : '_self'}
            key={button.label + index}
            tabIndex={index}
        >
            {button.icon}
            {button.label}
        </Link>)


    return (
        <div className="slide slick-bg" style={{backgroundImage: `url(${itemProps.backgroundImage})`}}>
            <div className="container-fluid position-relative h-100">
                <div className="slider-content h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <h3 data-animation-in="fadeInUp" data-delay-in={animationDelay}>
                                <span className="badge bg-warning text-dark">New</span>
                            </h3>
                            <h1 data-animation-in="fadeInUp" data-delay-in={animationDelay}>{itemProps.title}</h1>
                            <div
                                className="slide-info"
                                data-animation-in="fadeInUp"
                                data-delay-in={animationDelay}
                            >
                                <span
                                    className="radius">{itemProps.minimumAge}</span>{" "}
                                <span>{itemProps.duration}</span>
                            </div>
                            <p data-animation-in="fadeInUp" data-delay-in={animationDelay}>
                                {itemProps.description}
                            </p>
                            <div
                                className="slider-buttons d-flex align-items-center"
                                data-animation-in="fadeInUp"
                                data-delay-in={animationDelay}
                            >
                                {buttonsList}
                            </div>
                        </div>
                        {/* Col End */}
                    </div>
                    {/* Row End */}
                </div>
                {/* Slider Content End */}
            </div>
            {/* Container End */}
        </div>
    )

};


