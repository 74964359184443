import React, {useContext} from 'react';
import Shell from "../components/Shell";
import HomePageCarousel from "../components/HomePageCarousel";
import VideosSlider from "../components/VideosSlider";
import {Link} from "react-router-dom";
import {AppContext} from "../context/AppContext";
import {Themes} from "../models/Video";
import useVideo from "../hook/useVideo";
import {MutatingDots} from "react-loader-spinner";
import {shuffle} from "lodash";


export default function Home() {

    const {allVideos} = useContext(AppContext)
    const {filterVideosByTheme} = useVideo()

    const videosGames = filterVideosByTheme(Themes.JEUX_VIDEOS, allVideos)
    const videosManga = filterVideosByTheme(Themes.MANGAS, allVideos)


    return (
        <Shell>
            <>
                <HomePageCarousel/>
                {allVideos?.length > 0 ? (<>
                    <VideosSlider key={0} title={'Populaire'} videos={shuffle(allVideos).slice(0,20)}/>
                    <VideosSlider key={3} title={Themes.MANGAS} videos={videosManga!}/>
                    <VideosSlider key={2} title={Themes.JEUX_VIDEOS} videos={videosGames!}/>
                    <div className={"mb-5"} style={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <Link to={'/videos'} className={"btn hvr-sweep-to-right ml-3"}>Plus de vidéos</Link>
                    </div>
                </>) : <div className={"row align-items-center justify-content-center"} style={{height: "90vh"}}>
                    <MutatingDots
                        visible={true}
                        height="100"
                        width="100"
                        color="#e74e21"
                        secondaryColor="#e74e21"
                        radius="12.5"
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /></div>}
            </>
        </Shell>
    );

}
