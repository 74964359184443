import React from 'react';
import {Link} from 'react-router-dom';
import Shell from "../components/Shell";

export default function ForgetPassword() {

    return (
        <Shell>
            <div className="main-content">
                {/* Start Reset Password Section */}
                <section className="reset-password">
                    <div className="container">
                        <div className="row text-center intro">
                            <div className="col-12">
                                <h2>Mot de passe oublié ?</h2>
                                <p className="text-max-800">
                                    Tu peux réinitialiser ton mot de passe ici. Tu vas recevoir un email avec un nouveau mot de passe.
                                </p>
                            </div>
                            {/* Col End */}
                        </div>
                        {/* Row End */}
                        <div className="row h-100">
                            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                                <div className="d-table-cell align-middle">
                                    {/* Start Form */}
                                    <form id="reset-form" action="#" method="post">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                className="form-control form-control-lg"
                                                type="email"
                                                name="email"
                                                placeholder="Rentre ton email ici"
                                            />
                                        </div>
                                        <div className="text-center mt-3">
                                            <a href="#" className="btn hvr-sweep-to-right">
                                                Mettre à jour
                                            </a>
                                        </div>
                                    </form>
                                    {/* Form End */}
                                </div>
                            </div>
                            {/* Col End */}
                        </div>
                        {/* Row End */}
                    </div>
                    {/* Container End */}
                </section>
                {/* Reset Password Section End */}
            </div>

        </Shell>
    );

}
