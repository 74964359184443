import React from 'react';
import {Link} from "react-router-dom";
import Shell from "../components/Shell";

export default function NotFound() {

    return (
        <Shell>
            <section className={"404"}>
                <div className="container">
                    <div className="row">
                        <div className="content error-page text-center col">
                            <div className="error-code">
                                <h2>404</h2>
                            </div>
                            {/* Error Code end */}
                            <div className="error-message">
                                <h3>Oups... Cette page n'existe pas</h3>
                            </div>
                            {/* Error Message end */}
                            <div className="error-body">
                                <p>
                                    Essaye d'utiliser le bouton ci-dessous pour accéder à la page principale
                                </p>
                                <Link to='/' className="btn hvr-sweep-to-right">Retour à la page d'accueil</Link>
                            </div>
                            {/* Error Body end */}
                        </div>
                        {/* Content end */}
                    </div>
                    {/* Row end */}
                </div>
                {/* Container end */}
            </section>
        </Shell>
    );

}
