import React from "react";
import Shell from "../components/Shell";
import SubHeader from "../components/SubHeader";

export default function Copyrights() {


    return (
        <Shell>
            <>
                <SubHeader title={'Copyrights'}/>
                <div className="main-content">
                    <section className="terms">
                        <div className="container">

                            <p>TM&©2024 GONG MEDIA / OTT MEDIA. All rights reserved.</p>
                            <p>ANOTHER©2012 Yukito Ayatsuji・KADOKAWASHOTEN／Team Another - Licensed
                                through DRM Sprl</p>

                            <p>Battlefield Baseball © WE Productions 2010</p>

                            <p>Casshern Sins © 2008 YOSHIDA Tatsuo / Madhouse Production</p>

                            <p>Casshern sins © 2008 Tatsunoko Production/CASSHERN Sins Project</p>

                            <p>Castle Freak © Omnitem Communication © Neo Publishing</p>

                            <p>City Hunter © SBS 2011 Tous droits reservés</p>

                            <p>Class 1984 © Omnitem Communication © Neo Publishing</p>

                            <p>Darker than Black - Kuro no Keiyakusha © 2007 Bones, OKAMURA Tensai /
                                BONES</p>

                            <p>Deadman Wonderland © 2010 Jinsei Kataoka, Kazuma
                                Kondou/Kadokawashoten/Deadman Wonder
                                Land Ward G through DRM Sprl </p>

                            <p>Death Bed © Omnitem Communication © Neo Publishing</p>

                            <p>D.Gray-man © 2006 HOSHINO Katsura / TMS</p>

                            <p>Dr Alien © 1988 Phantom Productions © 2010 Omnitem Communication - © 2010
                                RV Films</p>

                            <p>Ex-Girlfriend Club © tvN 2015 Tous droits reservés</p>

                            <p>Flag © 2006 TAKAHASHI Ryousuke / Sunrise, Studio Answer</p>

                            <p>Flesh Gordon © 1974 Graffiti Productions - Tous droits réservés.</p>

                            <p>Harlequin © MCMLXXX Farelight Investments PTY LTD. All rights
                                reserved.</p>

                            <p>Hello Monster © KBS 2015 Tous droits reservés</p>

                            <p>Himawari à lécole des ninjas (saison 1) © 2006 GoDo / ARMS</p>

                            <p>Himawari à lécole des ninjas (saison 2) © 2007 GoDo / ARMS</p>

                            <p>Hunter × Hunter: Greed Island © 2003 TOGASHI Yoshihiro / Nippon
                                Animation</p>

                            <p>Hunter × Hunter: Greed Island Final © 2004 TOGASHI Yoshihiro / Nippon
                                Animation</p>

                            <p>Innocent Venus © 2006 KAWAGOE Jun / Brains Base</p>

                            <p>Intruder © Tous droits réservés</p>

                            <p>Iris © KBS 2009 Tous droits reservés</p>

                            <p>Killer Girl K © CJE&M 2011 Tous droits reservés</p>

                            <p>© CJ E&M Corporation</p>

                            <p>La Brute le Colt et le Karaté © Omnitem Communication. Tous droits
                                réservés. © Propriété
                                graphique 2005 Seven Sept </p>

                            <p>Le survivant d'un monde parallèle © Tuesday Films. Tous droits
                                réservés.</p>

                            <p>MICHIKO TO HATCHIN ( FINDING PARADISO ) © 2008 manglobe / Caliente latino
                                / TM&© GONG
                                2012</p>
                            <p>Moonlight Mile (saison 1) © 2007 OHTAGAKI Yasuo / Studio Hibari</p>


                            <p>My Japan - Based on an idea by David ZAVAGLIA / Director David ZAVAGLIA /
                                Production GONG
                                MEDIA ™&© GONG 2014p </p>
                            <p>Oh My Ghostess © tvN 2015 Tous droits reservés</p>

                            <p>Patrick © Australian Film Corp. & Patrick Productions. Tous droits
                                réservés.</p>


                            <p>Queens Blade © 2009 Hobby Japan / GENCO, ARMS</p>

                            <p>School Rumble © Jin Kobayashi/KODANSHA・Marvelous
                                Entertainment・SOTSU</p>

                            <p>Shock Waves © 1976 Zopix Company - All rights reserved.</p>

                            <p>StarCraft® II: Wings of LibertyTM, © Blizzard Entertainment, Inc. All
                                rights
                                reserved.</p>

                            <p>The Guard Post © WE Productions 2010</p>

                            <p>Vexille © 2007 Sori Fumihiko / Oxybot</p>

                            <p>Vigilante © Omnitem Communication</p>

                            <p>Wizard Barristers© Wid Partners</p>

                            <p>Yatterman © WE Productions</p>

                            <p>Yesterday's Target © I.R.S. Media / Pacific Shore Pictures</p>

                            <p>Yu-Gi-Oh! GX
                                ©1996 Kazuki Takahashi
                                ©2004 NAS • TV TOKYO</p>

                            <p> Yu-Gi-Oh! 5D's
                                ©1996 Kazuki Takahashi
                                ©2008 NAS • TV TOKYO</p>

                            <p> Yu-Gi-Oh! ZEXAL
                                ©1996 Kazuki Takahashi
                                ©2011 NAS • TV TOKYO</p>


                        </div>
                    </section>
                </div>
            </>
        </Shell>
    )
}
