import React from "react";
import Shell from "../components/Shell";
import SubHeader from "../components/SubHeader";

export default function Contact() {


    return (
        <Shell>
            <>
                <SubHeader title={'Contact'}/>
                <div className="main-content">
                    <section className="contact-us">
                        <div className="container">
                            <div className="row text-center intro">
                                <div className="col-12">
                                    <h2>CONTACTE-NOUS</h2>
                                    <p className="text-max-800">Tu as des questions ou des idées pour améliorer les chaînes GONG ? N'hésite pas à remplir le formulaire ci-dessous, et nous te répondrons rapidement. (Assure-toi d'inclure ton email !)</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <form className="contact__form" method="post" action="mail.php">

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-success contact__msg" style={{display: "none"}}
                                                     role="alert">
                                                    Ton message a bien été envoyé !
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <input name="name" type="text" className="form-control"
                                                       placeholder="Name" required/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input name="email" type="email" className="form-control"
                                                       placeholder="Email" required/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input name="phone" type="text" className="form-control"
                                                       placeholder="Téléphone" required/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input name="subject" type="text" className="form-control"
                                                       placeholder="Sujet" required/>
                                            </div>
                                            <div className="col-12 form-group">
                                                <textarea name="message" className="form-control" rows={4}
                                                          placeholder="Message" required></textarea>
                                            </div>
                                            <div className="col-12">
                                                <input name="submit" type="submit" className="btn btn-success"
                                                       value="Envoyer"/>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        </Shell>
    )
}
