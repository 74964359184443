import React from 'react';

export default function PreLoader() {

    return (
        <>
            {/* Start Preloader */}
            <div id="loader-wrapper">
                <div id="loader"/>
                <div className="loader-section section-left"/>
                <div className="loader-section section-right"/>
            </div>
        </>
    );

}
