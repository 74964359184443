import {Themes, Video} from "../models/Video";
import useFetch from "./useFetch";

export default function useVideo() {
    const host = process.env.REACT_APP_API_URL;
    const {getRequest} = useFetch();

    function getAllVideo() {
        return getRequest<Video[]>(`${host}/videos`);
    }

    function getVideoById(id:string) {
        return getRequest<Video>(`${host}/videos/${id}`);
    }

    const filterVideosByTheme = (theme: Themes, videos: Video[]) => {
        const filteredVideos = videos.filter(video => video.theme === theme)
        return filteredVideos.length > 0 ? filteredVideos : null
    }

    return {getAllVideo, getVideoById, filterVideosByTheme}
}
