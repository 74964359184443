import React, {useEffect, useRef} from 'react';
import Shell from "../components/Shell";
import Hls from 'hls.js';
import {Link} from "react-router-dom";


export default function Direct() {

    const hls = new Hls();
    const videoRef = useRef<HTMLVideoElement | null>(null);
    let fluxNotSupported = false;

    const STREAMING_FLUX_URL = 'https://amg01596-gongnetworks-gong-ono-vh5f2.amagi.tv/playlist.m3u8'

    useEffect(() => {
        initializeHls();
    }, []);

    function initializeHls() {
        if (Hls.isSupported() && hls && videoRef.current) {
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                hls.loadSource(
                    STREAMING_FLUX_URL
                );
            });
        } else if (videoRef.current && videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = STREAMING_FLUX_URL;
        } else {
            fluxNotSupported = true;
        }
    }

    return (
        <Shell>
            <div style={{display:'flex'}} className={'video-container align-items-center justify-content-center'}>
                {fluxNotSupported ?
                    <div className={'video d-block text-center'} style={{maxWidth: '80%'}}>
                        Le flux streaming est actuellement indisponible sur ton appareil.<br/><br/> Profites-en
                        pour <Link style={{textDecoration: 'underline'}} className={''} to={'videos'}>découvrir nos
                        vidéos
                        !</Link> 🙌
                    </div>
                    :
                    <video className={'video d-block'} ref={videoRef} controls/>
                }


            </div>
        </Shell>
    );

}
