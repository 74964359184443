import React, {useContext, useState} from 'react';
import Shell from "../components/Shell";
import VideosSlider from "../components/VideosSlider";
import {Themes, Video} from "../models/Video";
import {Link, useLocation} from "react-router-dom";
import {MutatingDots} from "react-loader-spinner";
import {AppContext} from "../context/AppContext";
import useVideo from "../hook/useVideo";
import {uniqBy} from "lodash"

export default function Videos() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filterByTitle = queryParams.get('s')
    const isFilteringByTitle = filterByTitle !== null
    const filterByTheme = queryParams.get('theme')
    const {allVideos} = useContext(AppContext)
    const {filterVideosByTheme} = useVideo()


    let videos: Video[] = filterByTitle ? allVideos.filter(video => {
        return video.title.toLowerCase().includes(filterByTitle.toLowerCase())
    }) : allVideos;

    if (filterByTheme) {
        videos = videos.filter(video => {
            return video.theme === filterByTheme
        })
    }

    const videosDoc = filterVideosByTheme(Themes.REPORTAGES, videos)

    const videosGames = filterVideosByTheme(Themes.JEUX_VIDEOS, videos)

    const videosInterviews = filterVideosByTheme(Themes.INTERVIEWS, videos)

    const videosAnime = filterVideosByTheme(Themes.ANIMES, videos)

    const videosBA = filterVideosByTheme(Themes.BANDES_ANNONCES, videos)

    const videosMusique = filterVideosByTheme(Themes.MUSIQUES, videos)

    const videosManga = filterVideosByTheme(Themes.MANGAS, videos)

    const videosCinema = filterVideosByTheme(Themes.CINEMA, videos)

    const [isDropDownVisible, setIsDropDownVisible] = useState(false)

    const calculateVideoSliderCount = () => {
        let count = 0;
        if (videosDoc && videosDoc.length !== 0) count++;
        if (videosGames && videosGames.length !== 0) count++;
        if (videosMusique && videosMusique.length !== 0) count++;
        if (videosManga && videosManga.length !== 0) count++;
        if (videosAnime && videosAnime.length !== 0) count++;
        if (videosCinema && videosCinema.length !== 0) count++;
        if (videosBA && videosBA.length !== 0) count++;
        if (videosInterviews && videosInterviews.length !== 0) count++;
        return count;
    };

    const renderVideos = () => {
        return videos && videos.length > 0 ?
            <> {isFilteringByTitle ? <VideosSlider key={10} title={"RECHERCHE"} videos={uniqBy(videos, 'title')}/> :
                <>{videosDoc && videosDoc.length !== 0 &&
                    <VideosSlider key={1} title={Themes.REPORTAGES} videos={videosDoc}/>}
                    {videosGames && videosGames.length !== 0 &&
                        <VideosSlider key={2} title={Themes.JEUX_VIDEOS} videos={videosGames}/>}
                    {videosMusique && videosMusique.length !== 0 &&
                        <VideosSlider key={3} title={Themes.MUSIQUES} videos={videosMusique}/>}
                    {videosManga && videosManga.length !== 0 &&
                        <VideosSlider key={4} title={Themes.MANGAS} videos={videosManga}/>}
                    {videosAnime && videosAnime.length !== 0 &&
                        <VideosSlider key={5} title={Themes.ANIMES} videos={videosAnime}/>}
                    {videosCinema && videosCinema.length !== 0 &&
                        <VideosSlider key={6} title={Themes.CINEMA} videos={videosCinema}/>}
                    {videosBA && videosBA.length !== 0 &&
                        <VideosSlider key={7} title={Themes.BANDES_ANNONCES} videos={videosBA}/>}
                    {videosInterviews && videosInterviews.length !== 0 &&
                        <VideosSlider key={8} title={Themes.INTERVIEWS} videos={videosInterviews}/>}</>}
            </>
            :
            <h2 style={{textAlign: "center", fontSize: "16px"}} className={"my-5"}> Aucune vidéo ne correspond à ta
                recherche &#128533; </h2>
    }

    const renderAllVideosSlider = () =>
        allVideos && allVideos.length > 0
        &&
        <VideosSlider key={0} title={'Toutes les videos'} videos={allVideos}/>


    return (
        <Shell>
            <div className={"main-content"}>
                <section style={{padding: "15px 0"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12"
                                 style={{
                                     display: "flex",
                                     alignItems: "center",
                                     justifyContent: "flex-end",
                                 }} onMouseLeave={() => {
                                setIsDropDownVisible(false)
                            }}>
                                <div className="nav-item dropdown" onMouseOver={() => {
                                    setIsDropDownVisible(true)
                                }}>
                                    <a style={{fontSize: "16px", fontWeight: "bold", padding: "0", lineHeight: "0"}}
                                       className="nav-link menu-dropdown mr-3"
                                       href="#">Thèmes <i className="fa fa-angle-down"/></a>
                                    <div style={{position: 'absolute', minHeight: '1000px'}}>
                                        <ul className={`dropdown-menu dropdown-menu-videos search-theme ${isDropDownVisible ? 'show-video-dropdown' : ''}`}

                                            role="menu">
                                            <li>
                                                <Link to={'/videos'}
                                                      className="dropdown-item">AUCUN</Link>
                                            </li>
                                            {Object.values(Themes).map((theme) => {
                                                return (<li key={theme}>
                                                    <Link to={`/videos?theme=${theme}`} onClick={() => {
                                                        setIsDropDownVisible(false)
                                                    }}
                                                          className="dropdown-item">{theme.toUpperCase()}</Link>
                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <li className="nav-item">
                                    <div className="nav-search">
                                        <a style={{fontSize: "16px", padding: "0", lineHeight: "0"}}
                                           className="nav-link modal-popup" href="#search-popup"><i
                                            className="icofont-ui-search"></i></a>
                                    </div>
                                </li>
                            </div>
                        </div>
                    </div>
                </section>

                {allVideos.length === 0 ? <div className={"row align-items-center justify-content-center"}>
                    <MutatingDots
                        visible={true}
                        height="100"
                        width="100"
                        color="#e74e21"
                        secondaryColor="#e74e21"
                        radius="12.5"
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /></div> : <>
                    {renderVideos()}
                    {renderAllVideosSlider()}
                </>}
                {calculateVideoSliderCount() > 3 && <section style={{padding: "15px 0"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12"
                                 style={{
                                     display: "flex",
                                     alignItems: "center",
                                     justifyContent: "center",
                                 }} onMouseLeave={() => {
                                setIsDropDownVisible(false)
                            }}>
                                <div className="nav-item dropdown" onMouseOver={() => {
                                    setIsDropDownVisible(true)
                                }}>
                                    <a style={{fontSize: "16px", fontWeight: "bold", padding: "0", lineHeight: "0"}}
                                       className="nav-link menu-dropdown mr-3"
                                       href="#">Thèmes <i className="fa fa-angle-down"/></a>
                                    <div style={{position: 'absolute', minHeight: '1000px'}}>
                                        <ul className={`dropdown-menu dropdown-menu-videos search-theme ${isDropDownVisible ? 'show-video-dropdown' : ''}`}

                                            role="menu">
                                            <li>
                                                <Link to={'/videos'}
                                                      className="dropdown-item">AUCUN</Link>
                                            </li>
                                            {Object.values(Themes).map((theme) => {
                                                return (<li key={theme}>
                                                    <Link to={`/videos?theme=${theme}`} onClick={() => {
                                                        setIsDropDownVisible(false)
                                                        window.scroll(0,0)
                                                    }}
                                                          className="dropdown-item">{theme.toUpperCase()}</Link>
                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            </div>
        </Shell>
    )
}
