import React, {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Video} from "../models/Video";
import useVideo from "../hook/useVideo";
import {createClient, Session, SupabaseClient} from "@supabase/supabase-js";
import {shuffle} from "lodash";


type AppContextType = {
    allVideos: Video[],
    setAllVideos: Dispatch<SetStateAction<Video[]>>
    session: Session | null,
    setSession: Dispatch<SetStateAction<Session | null>>,
    supabase:  SupabaseClient<any, "public", any> | null,
    windowWidth: number,
    setWindowWidth: Dispatch<SetStateAction<number>>,
}

export const AppContext = createContext<AppContextType>({
    allVideos: [],
    setAllVideos: () => {
        return "throw Error('setVideos not implemented');"
    },
    session: null,
    setSession: () => {
        return "throw Error('setSession not implemented');"
    },
    supabase: null,
    windowWidth: window.innerWidth,
    setWindowWidth: () => {
        return "throw Error('setWindowWidth not implemented');"
    }
});

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl!, supabaseKey!)
export default function AppContextProvider({children}:
                                               {
                                                   children: JSX.Element;

                                               }) {

    const {getAllVideo} = useVideo();
    const [allVideos, setAllVideos] = useState<Video[]>([])
    const [session, setSession] = useState<Session | null>(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    useEffect(() => {
        supabase!.auth.getSession().then(({data: {session}}) => {
            setSession(session)
        })

        //setup window width listener
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])


    useEffect(() => {
        getAllVideo().then((res: void | Video[]) => {
            setAllVideos(shuffle(res!))
        });
    }, []);

    return <AppContext.Provider value={{windowWidth, setWindowWidth, allVideos, setAllVideos, session, setSession, supabase}}>
        {children}
    </AppContext.Provider>
        ;
}
