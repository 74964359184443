import React, {useContext} from "react";
import Shell from "../components/Shell";
import SubHeader from "../components/SubHeader";
import {Link} from "react-router-dom";
import useToast from "../hook/useToast";
import {AppContext} from "../context/AppContext";

export default function AboutUs() {
    const {triggerConnectionRequieredToast} = useToast()

    const {session} = useContext(AppContext);


    return (
        <Shell>
            <>
                <SubHeader title={'Contact'}/>
                <div className="main-content" style={{paddingBottom: 0}}>
                    <section className="statistics">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="block-title">Nos Statistiques</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xl mb-5 mb-xl-0">
                                    <div className="icon-box text-center">
                                        <div className="icon"><i className="fas fa-video"></i></div>
                                        <div className="number count1" data-from="10" data-to="9300"
                                             data-time="1000">220
                                        </div>
                                        <p>Videos</p>
                                    </div>
                                </div>
                                <div className="col-6 col-xl mb-5 mb-xl-0">
                                    <div className="icon-box text-center">
                                        <div className="icon"><i className="fas fa-eye"></i></div>
                                        <div className="number count2" data-from="10" data-to="7400"
                                             data-time="1000">468
                                        </div>
                                        <p>Shows</p>
                                    </div>
                                </div>
                                <div className="col-6 col-xl mb-5 mb-xl-0">
                                    <div className="icon-box text-center">
                                        <div className="icon"><i className="fas fa-users"></i></div>
                                        <div className="number count3" data-from="10" data-to="1500"
                                             data-time="1000">1 115 000
                                        </div>
                                        <p>Followers</p>
                                    </div>
                                </div>
                                <div className="col-6 col-xl mb-5 mb-xl-0">
                                    <div className="icon-box text-center">
                                        <div className="icon"><i className="fas fa-trophy"></i></div>
                                        <div className="number count4" data-from="10" data-to="860" data-time="1000">2
                                        </div>
                                        <p>Awards</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="gap-50"></div>
                    <section className="subscribe section-parallax">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6" style={{borderRadius: '10px', textAlign:'center'}}>
                                    <img style={{borderRadius: '10px', maxHeight:'577px'}} className="img-fluid mb-4"
                                         src="/images/molotov-vertical.jpeg" alt=""/>
                                </div>
                                <div className="col-md-6">
                                    <h2 className="mb-4">Et si tu participais à l'expérience ?</h2>
                                    <p>Bienvenue sur GONG, la destination ultime pour le divertissement
                                        asiatique ! Depuis 2006, nous nous sommes engagés à proposer le meilleur de la
                                        culture asiatique, avec une attention toute particulière portée sur la Corée du
                                        Sud,
                                        le berceau de la célèbre "vague Coréenne", également connue sous le nom de
                                        Hallyu.

                                    </p>
                                    <p>On t'invite à plonger dans un univers riche en découvertes, où tu pourras
                                        explorer
                                        une variété d’interviews d'artistes et de programmes captivants. Des dramas
                                        envoûtants aux animes palpitants, en passant par les compétitions d'e-sports et
                                        les
                                        infos sur les JV, notre sélection couvre tous les aspects du divertissement
                                        asiatique. Tu pourras également te laisser emporter par l'univers envoûtant de
                                        la
                                        K-pop, découvrir les coulisses des célébrités qui animent la scène, et bien plus
                                        encore.</p>

                                    <p className="mb-4"> Pour accéder à notre éventail complet de contenus, rejoins-nous
                                        sur
                                        Dramapassion, la
                                        première plateforme francophone de streaming de dramas coréens, ou sur STAR 24,
                                        ton
                                        portail incontournable des gossips de la K-culture. Tu peux également profiter
                                        de
                                        nos chaînes GONG et GONG MAX, disponibles chez les opérateurs Free, Bouygues
                                        Telecom, Molotov et Samsung TV Plus.</p>


                                    <div style={{display: "flex", justifyContent: 'space-between'}}>
                                        <Link style={{
                                            marginRight: 5,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} to={session ? '/live' : ''} onClick={() => {
                                            !session && triggerConnectionRequieredToast()
                                        }} className="btn hvr-sweep-to-right">GONG EN LIVE</Link>
                                        <Link style={{
                                            marginRight: 5,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                              target={"_blank"} to={'https://star24.tv/'}
                                              className="btn hvr-sweep-to-right">STAR 24</Link>
                                        <Link
                                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                            target={"_blank"}
                                            to={'https://www.dramapassion.com/'}
                                            className="btn hvr-sweep-to-right">DRAMAPASSION</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        </Shell>
    )
}
